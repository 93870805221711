window.DEVICE_TYPE = typeof DEVICE_TYPE !== "undefined" ? DEVICE_TYPE : "";
window.UseUrlNav = typeof UseUrlNav !== "undefined" ? UseUrlNav : false;
 
var Application = ((function ()
{
    /*jshint maxstatements:100*/
    'use strict';

    var api = {};

    var CPanelClassName = 'page';
    var CActivePanelClassName = 'current';

    api.Panels = {};
    api.AllBlocks = {};
    //var FirstPanelID = '';

    var CurrentHash = '';
    var CurrentPanel = null;
    var DefaultPanelID = '';
    var hashSplitRegex = /[#!$&'\(\)\*\+,;=]/;

    var onhashcangecalled = false;

    var allowPanelTransition = true;
    var eventsManager = new EventsManager();

    var msgBus = null;
    var msgBusChannels = null;
    var isAppInitialized = false;

    api.BeforeSwitchPanels = [];
    api.AfterSwitchPanels = [];

    api.BeforeNavigation = [];
    api.AfterNavigation = [];
    api.onRemoveSplash = [];
    api.onAfterApplicationInit = {};
    api.onBeforeApplicationInit = {};
    api.cashierPopupWindow = null;

    api.init = function () {
        // Start Check for DGT-42578 -> Duplicating events on IOS
        if (isAppInitialized) {
            console.warn("Application already initialized!");
            return;
        }
        checkIpadDesktopMode();
        // End Check for DGT-42578 -> Duplicating events on IOS
        executeEvents(api.onBeforeApplicationInit);
        PanelSwitcher.removeAllRenderedBlocks();
        setClassForIframe();
        setClassForSpecificIOS(11);
        setClassForIPhoneX(UI.detects.detectIPhoneX());

        if (!this.deviceType.isDesktop())
        {
            api.goToBodyTop.init('gotoTop');
            setMobileOSFlag();
        }

        if (window.sbInternalMsgBus) {
            msgBus = window.sbInternalMsgBus.internalMessageBus;
            msgBusChannels = window.sbInternalMsgBus.InternalMessageBusChannels;
        }

        if (Application.IsNativeApp()) {
            api.initNativeHomeRedirection();
        }

        api.rightPanelsHolder.init();
        api.leftPanelsHolder.init();
        api.centerPanelHolder.init();
        api.topPanelsHolder.init();
        api.bottomPanelsHolder.init();
        api.rightNavPanelsHolder.init();


        api.registerPanel(MobileLeftPanel.EmptyPanel);
        api.registerPanel(MobileRightPanel.EmptyPanel);
        api.registerPanel(MobileCenterPanel.EmptyPanel);

        api.winResizeEvent.init();
        api.winResizeEvent.addCallback({
            name: 'updateFooterLocation',
            func: api.updateFooterLocation,
            context: api
        });
        api.deviceOrientation.init();

        if (useCrossSiteCommunication)
        {
            CrossDocumentCommunicatonCallbacks.registerCallbacks();
        }

        for (var i in api.Panels)
        {
            if (api.Panels.hasOwnProperty(i))
            {
                api.Panels[i].init();
            }
        }

        localStorage.setItem('locationHash', document.location.hash);

        var navigateToHash = function () { api.navigateToHash(location.hash ? location.hash : DefaultPanelID); };
        var navigate = UseUrlNav ? this.Navigation.init : navigateToHash;
        !findToteEventViewBlock() && navigate();

        if (Application.IsNativeApp())
        {
            restoreState();
			api.initNativeHomeRedirection();
            document.documentElement.classList.add('IsNativeiOS');
        }

        if (this.deviceType.isDesktop() && typeof UITooltip !== 'undefined')
        {
            api.tooltip = new UITooltip();
        }

        customSelect.init();

        api.initAsiaDeepLinks();
        initToteEventViewDeepLinking();
        
		executeEvents(api.onAfterApplicationInit);
        isAppInitialized = true;
    };

    function checkIpadDesktopMode()
    {
        var cookieName = 'forceTabletViewForIpad';
        var cookieValue = 'set';
        var isTouchDevice = /(ipad)/im.test(navigator.platform) || (navigator.maxTouchPoints > 0 && /(macintel)/im.test(navigator.platform));
        var shouldSetCookie = isFullBetslip && api.deviceType.isDesktop() && isTouchDevice && !Cookies.get(cookieName);
        if (shouldSetCookie)
        {
            Cookies.set(cookieName, cookieValue);
            if (Cookies.get(cookieName) === cookieValue)
            {
                location.reload();
            }
        }
    }

    function setClassForIframe()
    {
        api.isInIframe() && document.documentElement.classList.add('is-in-iframe');
    }

    // Add a specific class to target iOS versions lower than provided number
    // when the application is inside an iframe
    function setClassForSpecificIOS(number)
    {
        if (api.isInIframe() && UI.detects.mobileDetect.version('iOS') < number)
        {
            document.documentElement.classList.add('is-old-ios');
        }
    }

    function setClassForIPhoneX(isIPhoneX)
    {
        if (isIPhoneX)
        {
            document.documentElement.classList.add('is-iphone-x');
        }
    }

    function setMobileOSFlag()
    {
        var os = UI.detects.mobileDetect.os();
        os && document.documentElement.setAttribute('data-mobile-os', os.toLowerCase());
    }

    api.isInIframe = function () 
    {
        return window.self !== window.top;
    };

    api.preventBodyScroll = (function()
    {
        var mainNavOverLay = {};
        var panelOverlay = {};
        var initialiazed = false;

        function init()
        {
            mainNavOverLay = document.getElementById('main-nav-overlay');
            panelOverlay = document.getElementById('panel-overlay');
            initialiazed = true;
        }

        function activate(element, overlay)
        {
            if (!(element instanceof HTMLElement)) { return; }

            if (initialiazed === false)
            {
                init();
            }
            element.addEventListener('touchstart', touchStartHandler, false);
            element.addEventListener('touchmove', touchMoveHandler, false);

            mainNavOverLay.addEventListener('touchmove', preventScroll, true);
            panelOverlay.addEventListener('touchmove', preventScroll, true);
            if (overlay instanceof HTMLElement) {
                overlay.addEventListener('touchmove', preventScroll, true);
            }
        }

        function deactivate(element, overlay)
        {
            if (!(element instanceof HTMLElement)) { return; }

            if (initialiazed === false)
            {
                init();
            }
            element.removeEventListener('touchstart', touchStartHandler);
            element.removeEventListener('touchmove', touchMoveHandler);

            mainNavOverLay.removeEventListener('touchmove', preventScroll);
            panelOverlay.removeEventListener('touchmove', preventScroll);

            if (overlay instanceof HTMLElement) {
                overlay.removeEventListener('touchmove', preventScroll);
            }
        }

        /* SCROLLING HANDLING \/ */
        var Y, dY;

		function touchStartHandler(e)
		{
            Y = parseInt(e.changedTouches[0].clientY);
        }

        function touchMoveHandler(e)
		{
            dY = parseInt(e.changedTouches[0].clientY) - Y;
            /*jshint validthis: true*/
			if((dY > 0 && this.scrollTop === 0) || ( dY < 0 && this.scrollHeight - this.scrollTop === this.clientHeight))
			{
                preventScroll(e);
            }
        }

        function preventScroll(e)
	    {
            e.preventDefault();
            e.stopPropagation();
        }
        /* SCROLLING HANDLING /\ */

        return {
            init: init,
            activate: activate,
            deactivate: deactivate
        };
    })();

    api.getLeftPanel = function ()
    {
        return Array.getValues(api.Panels).where(function (p) { return p instanceof MobileLeftPanel && p.isActive; })[0];
    };

    api.getRightPanel = function ()
    {
        return Array.getValues(api.Panels).where(function (p) { return p instanceof MobileRightPanel && p.isActive; })[0];
    };

    api.getHomePanel = function ()
    {
        return Array.getValues(api.Panels).firstOrDefault(function (p) { return p instanceof HomePanel; });
    };

    var setHash = function (hash)
    {
        if (!hash)
        {
            hash = DefaultPanelID;
        }

        var shash = hash.split(hashSplitRegex);
        if (hash[0] == '#')
        {
            shash.shift();
        }

        var panelid = shash[0];

        CurrentHash = panelid;

        var newHash = shash.join('#');
        var chash = window.location.hash;

        if (chash.length > 0 && chash[0] == '#')
        {
            chash = chash.substr(1);
        }

        if (chash != newHash)
        {
            onhashcangecalled = true;
            window.location.hash = newHash;
        }
    };

    api.navigateToHash = function (hash)
    {
        // fuck, fuck fuck android 2.2 browser !!!

        hash = unescape(hash);
        var sHash = hash.split(hashSplitRegex);
        if (hash[0] == '#')
        {
            sHash.shift();

            var isPopupPanel = api.Panels[sHash[0]] && api.Panels[sHash[0]].Position == PanelPosition.POPUP;
            !isPopupPanel && window.scrollTo(0, 0);
        }

        var panelid = sHash[0];
        if (!panelid)
        {
            panelid = DefaultPanelID;
        }

        api.navigateTo(panelid, true, sHash.slice(1));
    };

    /* jshint maxparams: 5, maxcomplexity: 12 */ // TODO: Refactor to decrease complexity
    api.navigateTo = function (panelid, isBackCall, params, path, shouldHideSidePanels)
    {
        if (!allowPanelTransition)
        {
            return;
        }

        var panel = api.getPanel(panelid);
        if (CurrentPanel === panel)
        {
            if (!Array.isArray(params))
            {
                params = [params];
            }

            CurrentPanel.setParams(params);
        }

        if (!panel || panel.isPeripheralPanel())
        {
            return;
        }

        var callbackParams = {
            panel: panel,
            params: params,
            path: path,
            isBackCall: isBackCall,
            pageNavigationConfig: api.Navigation.pageNavigationConfig
		};

        executeEvents(api.BeforeNavigation, callbackParams);

        if (!UseUrlNav) {
            var newHash = panelid;
            if (params) {
                if (!Array.isArray(params)) {
                    params = [params];
                }
                newHash = [panelid].concat(params).join('#');
            }

            setHash(newHash);
        }

        executeEvents(api.AfterNavigation, callbackParams);

        if (CurrentPanel === panel)
        {
            if (shouldHideSidePanels)
            {
                CurrentPanel.hideSidePanels();
            }

            return;
        }

        switchPanel(
            panel,
            isBackCall,
            params);
    };

    api.getPanel = function (panelid)
    {
        return api.Panels[panelid] || Array.prototype.firstOrDefault.call(api.Panels, function (p)
        {
            return p.defaultCode == panelid;
        });
    };

    api.loadVirtualScriptBeforeNav = function (branchPaneID, isSide, branchIdarr, path, brID) {
        /* globals vsmobile, BetRadarSportsConstants*/
        if (BetRadarSportsConstants[brID] && typeof vsmobile !== "undefined" && vsmobile.instance) {
            vsmobile.instance.destroy();
        }

        Application.navigateTo(branchPaneID, isSide, branchIdarr, path);
    };

    api.localStorageCheck = function () {};

    /* jshint maxparams: 7, maxcomplexity: 14 */ // TODO: Refactor to decrease params
    var switchPanel = function (toPanel, reverse, params)
    {
        allowPanelTransition = false;
        executeEvents(api.BeforeSwitchPanels, CurrentPanel, toPanel);

        var transitionSettings = {
            Reverse: reverse,
            LeaveSettings: reverse ? 'fade out' : 'fade in',
            EnterSettings: reverse ? 'slide in' : 'slide in reverse',
            Params: params
        };

        let previousPanel = CurrentPanel;
        //set current panel before switch because some blocks use Application.getCurrent() during initialization/setParams 
        CurrentPanel = toPanel;
        // previousPanel will be null only the first time the page loads. In this case we use a dummy center panel with no blocks and left/right panels to switch from
        previousPanel ? previousPanel.switchToPanel(toPanel, transitionSettings) : MobileCenterPanel.EmptyPanel.switchToPanel(toPanel, transitionSettings);

        api.localStorageCheck();

        allowPanelTransition = true;
        executeEvents(api.AfterSwitchPanels, CurrentPanel, toPanel);
        return;
    };

    api.enableTransition = function ()
    {
        allowPanelTransition = true;
    };

    api.getCurrent = function ()
    {
        return CurrentPanel;
    };

    api.getMobileLeftNavigationPanel = function ()
    {
        return Array.find(Application.Panels, function (panel) { return panel instanceof MobileLeftNavigationPanel; });
    };

    api.goBack = function (forceRefresh)
    {
        ApplicationHistory.goBack(forceRefresh);
        return false;
    };

    api.reload = function ()
    {
        window.location.reload();
    };

    api.goHome = function (forceReload)
    {
        var currentPath = location.pathname;
        var homePath = api.Navigation.adjustSportsPath('/');
        var homePanel = api.getHomePanel();
        var needReload = !UseUrlNav ? currentPath !== homePath : !homePanel;
        //when the user is on a different page than home page
        if (forceReload || (currentPath.length > 1 && needReload))
        {
            location.href = homePath;
            return false;
        }

        var targetPanelID = homePanel ? homePanel.ID : DefaultPanelID;

        if (UseUrlNav) {
            api.navigateTo(targetPanelID, false, [], homePath, true);
        }
        else {
            location.hash = targetPanelID;
        }

        return false;
    };

    api.registerPanel = function (panel)
    {
        if(api.Panels[panel.ID]){
            console.warn(`panel with id ${panel.ID} is already registered`);
        }
        api.Panels[panel.ID] = panel;
        panel.panelClassName = CPanelClassName;
        panel.activePanelClassName = CActivePanelClassName;

        // only center panel can be the default one
        if (!DefaultPanelID && panel.Position == PanelPosition.CENTER)
        {
            DefaultPanelID = panel.ID;
        }
    };

    api.registerBlock = function (panelID, block, blockUniqueID)
    {
        var panel = api.Panels[panelID];
        block.BlockUniqueID = blockUniqueID || block.BlockUniqueID;
        block.PanelID = panelID;
        block.panelId = panelID; // compatibility fix
        if(this.AllBlocks[block.BlockUniqueID]) {
            console.warn(`block with id ${block.BlockUniqueID} is already registered`);
        }
        this.AllBlocks[block.BlockUniqueID] = block;
        panel.registerBlock(block);
    };

    api.showSplash = function ()
    {
        var splashScreen = document.getElementById('splash_screen');
        if (splashScreen)
        {
            splashScreen.style.display = '';
            document.body.classList.add('hideblocks');
        }
    };

    api.removeSplash = function ()
    {
        var splashScreen = document.getElementById('splash_screen');
        if (splashScreen)
        {
            splashScreen.style.display = 'none';
            document.body.classList.remove('hideblocks');
            executeEvents(api.onRemoveSplash, 'App');
        }
    };

    api.IsNativeApp = function ()
    {
        return (typeof Native != 'undefined' || typeof Android != 'undefined');
    };

    api.IsIosBrowser = function ()
    {
        return !window.navigator.standalone && isIos();
    };

    function isIos()
    {
        return /(ipad|iphone|ipod)/g.test(window.navigator.userAgent.toLowerCase()) && !window.MSStream;
    }

    api.gotoPos = function (posY)
    {
        var gotoTopBtn = document.getElementById('gotoTop');
        var gotoTop = function (e)
        {
            e.preventDefault();
            posY = posY || 0;
            api.scrollContainerGlobal.scroll(0, posY);

            Application.gotoPos().hide();
        };


        function show()
        {
            if (!gotoTopBtn)
            {
                return;
            }

            gotoTopBtn.classList.add('isShown');
            gotoTopBtn.addEventListener('click', gotoTop, false);
        }

        function hide()
        {
            if (!gotoTopBtn)
            {
                return;
            }

            gotoTopBtn.classList.remove('isShown');
            gotoTopBtn.removeEventListener('click', gotoTop, false);
        }

        return {
            show: show,
            hide: hide
        };
    };

    window.onhashchange = function ()
    {
        if (!UseUrlNav && !onhashcangecalled)
        {
            api.navigateToHash(location.hash);
        }

        onhashcangecalled = false;
    };

    api.deviceType =
    {
        type: DEVICE_TYPE,

        get: function ()
        {
            return this.type;
        },

        set: function (device)
        {
            eventsManager.dispatchEvent('onDeviceTypeChange', device, true);

            if (this.type === device)
            {
                return 'same device type';
            }
            //validate device
            var valid = false;
            var devicesKeys = Object.keys(UI.detects.deviceTypes);
            var i = 0;
            while (i < devicesKeys.length)
            {
                if (UI.detects.deviceTypes[devicesKeys[i]] === device)
                {
                    document.documentElement.className = document.documentElement.className.replace(this.type, device);
                    this.type = device;
                    valid = true;
                    break;
                }
                i += 1;
            }

            return valid ? this.type : 'Device type not recognized';
        },

        isMobile: function ()
        {
            return this.type === UI.detects.deviceTypes.MOBILE;
        },

        isTablet: function ()
        {
            return this.type === UI.detects.deviceTypes.TABLET;
        },

        isDesktop: function ()
        {
            return this.type === UI.detects.deviceTypes.DESKTOP;
        }
    };

    api.scrollToTop = function (block)
    {   
        var panel = typeof block === 'object' && typeof block.getPanel === 'function' ? block.getPanel() : undefined;
        var scroller = panel ? panel.customScroller : undefined;
        if (scroller)
        {
            scroller.scrollTop();
        }

        else
        {
            var isTabletDevice = api.deviceType.isTablet();
            var scrollContainer = isTabletDevice ? api.centerPanelHolder.element : window;

            isTabletDevice ? scrollContainer.scrollTop = 0 : scrollContainer.scroll(0, 0);
        }

    };

    api.goToBodyTop = (function()
    {
        var btn;
        var shown = false;


        function init(btnID)
        {
            btn = document.getElementById(btnID);
            api.scrollContainerGlobal = api.deviceType.isTablet() ? api.centerPanelHolder.element : window;

            if (btn === null)
            { return 'invalid btnID'; }

            api.scrollContainerGlobal.addEventListener('scroll', debounce(scrollHandler, 50), false);
        }

        function goToTop()
        {
            api.deviceType.isTablet() ? api.scrollContainerGlobal.scrollTop = 0 : api.scrollContainerGlobal.scroll(0, 0);
        }

        function scrollHandler()
        {
            var exceedWindow = api.scrollContainerGlobal.scrollTop || api.scrollContainerGlobal.scrollY > window.innerHeight;

            if (shown === false && exceedWindow)
            {
                shown = true;
                btn.classList.add('isShown');
            }
            else if (shown === true && !exceedWindow)
            {
                shown = false;
                btn.classList.remove('isShown');
            }
        }

        return {
            init: init,
            goToTop: goToTop
        };
    })();

    api.deviceOrientation =
    {
        orientation: '',
        init: function()
        {
            this.orientation = UI.detects.getDeviceOrientation();
            document.documentElement.classList.add(this.orientation);

            return this.orientation;
        },
        get: function ()
        {
            return this.orientation;
        },
        set: function(_orientation)
        {
            eventsManager.dispatchEvent('onDeviceOrientationChange', _orientation, true);

            if (this.orientation === _orientation)
            {
                return 'same orientation';
            }

            //validate
            var valid = false;
            var orientationKeys = Object.keys(UI.detects.deviceOrientations);
            var i = 0;
            while (i < orientationKeys.length)
            {
                if (UI.detects.deviceOrientations[orientationKeys[i]] === _orientation)
                {
                    document.documentElement.className = document.documentElement.className.replace(this.orientation, _orientation);
                    this.orientation = _orientation;
                    valid = true;
                    break;
                }
                i += 1;
            }

            return valid ? this.orientation : 'Orientation type not recognized';
        }
    };
    /*
    Application.winResizeEvent.addCallback(
        {
            name: 'myCallback',                 //{STRING} Use to remove the callback REQUIRED
            func: function() {...},             //{FUNCTION} anonymous or reference REQUIRED
            args: [argumentA, argumentB, ...],  //{ARRAY} Arguments for the function OPTIONAL
            context: myObj                      //{OBJECT} Pass a context OPTIONAL
        }
    );
    Application.winResizeEvent.removeCallback('myCallback'); //{STRING} The "name" property of a callback object
    */
    api.winResizeEvent =
    {
        init: function()
        {
            var winResizeHandlerRef = this.handler.bind(this);
            var winResizeHandler = debounce(winResizeHandlerRef, 50);
            window.addEventListener('resize', winResizeHandler, true);
        },
	    handler: function()
	    {
            UI.detects.updateMobileDetect();
            var detectedDevice = UI.detects.getDeviceType();
            var detectedOrientation = UI.detects.getDeviceOrientation();

            if (Application.deviceType.get() === detectedDevice && Application.deviceOrientation.get() === detectedOrientation)
            {
                return 'no change.';
            }
            Application.deviceType.set(detectedDevice);
            Application.deviceOrientation.set(detectedOrientation);

            for (var i = 0; i < this.callbacks.length; i +=1)
            {
                this.callbacks[i].func.apply(this.callbacks[i].context, this.callbacks[i].args);
            }
        },
        callbacks: [],
	    addCallback: function(obj)
	    {
            if (typeof obj === 'object' && typeof obj.name === 'string' && typeof obj.func === 'function')
            {
                if (!this.checkCallback(obj.name))
                {
                    obj.args = (obj.args instanceof Array) ? obj.args : null;
                    obj.context = (typeof obj.context === 'object') ? obj.context : null;
                    this.callbacks.push(obj);
                    return obj;
                }
                else
                {
                    return 'duplicate callback object';
                }
            }
            else
            {
                return 'invalid argument: callback object';
            }
        },
	    removeCallback: function(callbackObjName)
	    {
            if (typeof callbackObjName === 'string')
            {
                var callbackObj;

		        for (var i = 0; i < this.callbacks.length; i += 1)
                {
                    if (this.callbacks[i].name === callbackObjName)
                    {
                        callbackObj = this.callbacks[i];
                        this.callbacks.splice(i, 1);
                        return callbackObj;
                    }
                }
            }
            else
            {
                return 'invalid argument: callback object name';
            }
        },

        checkCallback: function(callbackObjName)
        {
		    for (var i = 0; i < this.callbacks.length; i += 1)
            {
                if (this.callbacks[i].name === callbackObjName)
                {
                    return true;
                }
            }

            return false;
        }
    };

    api.updateFooterLocation = function()
    {
        var footer = document.getElementById('panels-bottom-holder');
        if (Application.deviceType.isDesktop() && footer.parentElement.tagName !== 'BODY') 
        {
            footer.parentElement.removeChild(footer);
            var contentMain = document.getElementById('content-main');
            contentMain.parentNode.insertBefore(footer, contentMain.nextElementSibling);
        } 
        else if(footer.parentElement.id !== 'pagesWrapper')  
        {
            footer.parentElement.removeChild(footer);
            document.getElementById('pagesWrapper').appendChild(footer);
        }
    };

    api.blockAction = function (panelID, blockUniqueID, actionName)
    {
        var panel = api.Panels[panelID];
        var block = panel && panel.Blocks.firstOrDefault(function (b) { return b.BlockUniqueID === blockUniqueID; });

        if (!block || !block[actionName] || typeof block[actionName] !== 'function')
        {
            return;
        }

        block[actionName].apply(block, Array.prototype.slice.call(arguments, 3));
    };

    api.OpenPopUpGame = function(event, id, isLive, eveTypeForGameBlock, shouldHideSidePanels, branchID, leagueID)
  	{
        window.event && window.event.preventDefault && window.event.preventDefault();
        var currentPanel = Application.getCurrent();

      	if(currentPanel && currentPanel.Position ===  PanelPosition.POPUP)
        {
            Application.goBack();
        }
        var url = Dom.getFirstSubTreeHref(window.event.target);
        setTimeout(Application.OpenGame.bind(this, window.event, id, isLive, eveTypeForGameBlock, shouldHideSidePanels, branchID, leagueID, url), 100);
    }


    /* globals UseUrlNav, VirtualSports,  SBTech */
    api._openGameResponsive = function (event, id, isLive, evTypeForGameBlock, shouldHideSidePanels, branchID, leagueID, url)
    {
        if (!branchID) {
            function connection() {
                this.query = undefined;
                this.onData = (data) => {
                    var sportId = -1;
                    var eventType = evTypeForGameBlock;
                    if (data && data.events && data.events[0] && data.events[0].sportId) {
                        var sportEvent = data.events[0];
                        sportId = Number(sportEvent.sportId);

                        var isLiveOutright = sportEvent.isLive && sportEvent.type === "Outright";
                        eventType = isLiveOutright ? 199 : eventType;
                    }

                    if (sportId === eBranches.SoccerAggregates || isLiveOutright) {
                        api.OpenGame(event, id, isLive, eventType, shouldHideSidePanels, sportId, leagueID);
                    }
                    this.query = undefined
                }
            }
            const conn = new sbDataLayer.Sportsbook.connection()(connection)()
            conn.query = sbDataLayer.Sportsbook.queryBuilder.getEventsByEventId({ ids: [id.toString()], includeFields: ["Events"]})
        }

        var isBranchVirtual = VirtualSports.isBranchVirtual(branchID);
        
        var liveGamePanelCode = api.Navigation.pageNavigationConfig.LiveBettingNavigationCode;

        var liveGamePanel = Array.find(api.Panels, function (panel) {
            return panel.Position == PanelPosition.CENTER && panel.hasBlock('EventViewResponsiveBlock') && panel.ID === liveGamePanelCode;
        });

        var liveAggegatedEventMobilePanel = Array.find(api.Panels, function(panel){
            return panel.Position == PanelPosition.CENTER && panel.hasBlock('EventViewResponsiveBlock') && panel.hasBlock('AggregatesViewPresenterBlock')
        });

		var preLiveGamePanel = isBranchVirtual ? Array.find(api.Panels, function (panel) {
			return panel.Position == PanelPosition.CENTER && panel.hasBlock('EventViewResponsiveBlock') && panel.hasBlock('VirtualLeagueResponsiveBlock') && panel.ID !== liveGamePanelCode;
		}) : Array.find(api.Panels, function (panel) {
			return panel.Position == PanelPosition.CENTER && panel.hasBlock('EventViewResponsiveBlock') && panel.ID !== liveGamePanelCode && !panel.hasBlock('VirtualLeagueResponsiveBlock');
		});

        var liveOverViewPanel = !api.deviceType.isMobile() ? liveGamePanel :
            Array.find(api.Panels, function (panel) {
                return panel.Position == PanelPosition.CENTER && panel.hasBlock('LiveNowBettingResponsiveBlock');
            });

        if (isLive && !id && !api.deviceType.isDesktop()) { // navigate to mobile/tablet live-betting overview panel if there is no particular event id
            liveGamePanel = liveOverViewPanel;
        }

        var virtualGamePanel = Array.find(api.Panels, function (panel) {
            return panel.Position === PanelPosition.CENTER && panel.hasBlock('VirtualLeagueResponsiveBlock') && panel.Blocks.any(function(block) { return block.Configuration.LeagueID === leagueID; });
        });

        var params = [id, evTypeForGameBlock, branchID];

        if (isBranchVirtual)
        {
            params = [branchID, , leagueID];
        }

        if (typeof eBranches.SoccerAggregates !== "undefined" && 
            branchID === eBranches.SoccerAggregates && 
            id > 0 &&
            Application.deviceType.isMobile())
        {
            liveGamePanel = liveAggegatedEventMobilePanel || liveGamePanel;
            typeof (AggregatedEventsUtils) !== "undefined" && typeof (AggregatedEventsUtils.setAggregatedId) === "function" && AggregatedEventsUtils.setAggregatedId(id);
        }

        var defaultPanel = preLiveGamePanel || liveGamePanel;

        var toPanel = (isLive
            ? liveGamePanel
            : isBranchVirtual
                ? virtualGamePanel
                : preLiveGamePanel)
        || defaultPanel;

        // === TODO: remove when header is developed ==
        var liveBettingBlock = liveGamePanel && liveGamePanel.LeftPanel.getBlock('LiveNowBettingResponsiveBlock');
        if (isLive && liveBettingBlock && Application.getCurrent() == liveGamePanel)
        {
            if (!liveBettingBlock.ActiveEventId && id)
            {
                liveBettingBlock.setActiveEventId(id);
            }
            
            liveBettingBlock.isOverview && liveBettingBlock.switchView(liveBettingBlock.eventViewMode);
            

        }
        // ============================================

        window.scrollTo(0, 0);
        var path;

        if (UseUrlNav && event)
        {
            if (!toPanel) {
                return;
            }
            event.preventDefault && event.preventDefault();
            path = Dom.getFirstSubTreeHref(event.target) || url ;
        }

        api.navigateTo(toPanel.ID, false, params, path, shouldHideSidePanels);

        if (window.LegacySportsFacade && event !== window.LegacySportsFacade.sourceId && SBTech.topics.eventViewTopics && SBTech.topics.eventViewTopics.openEvent) { // to break cyclic notifications
            SBTech.nextGenBus.publish({
                topic: SBTech.topics.eventViewTopics.openEvent,
                data: {
                    id: id,
                    isLive: isLive
                }
            });
        }
    }

    api._openGameNative = function(event, id, isLive, eventTypeForGameBlock, shouldHideSidePanels, branchID, leagueID)
    {
        if (Application.IsNativeApp() && RedirectToNativeEventView) {
            var message = {};
            event && event.preventDefault && event.preventDefault();
            window.event && window.event.preventDefault && window.event.preventDefault();
            message[sbWrapperIntegrations.NativeNavigationChannels.openNativeEventView] = {
                eventId: id.toString(),
                sportId: branchID.toString(),
                isOutright: eventTypeForGameBlock === 99 || eventTypeForGameBlock === 199,
    
            };
            sbMsgBus.post(message);
        }
    };

    api.OpenGame = function (event, id, isLive, evTypeForGameBlock, shouldHideSidePanels, branchID, leagueID, url)
    {     
        if (Application.IsNativeApp() && RedirectToNativeEventView) {
            api._openGameNative(event, id, isLive, evTypeForGameBlock, shouldHideSidePanels, branchID, leagueID);
            return;
        }
        api._openGameResponsive(event, id, isLive, evTypeForGameBlock, shouldHideSidePanels, branchID, leagueID, url);
    };
	
  	api.OpenCoupon = function (branchId, couponLeagues)
    {
      var couponPanel = Array.find(Application.Panels, function (panel)
        {
            return panel.Position === PanelPosition.CENTER && panel.hasBlock('CouponLeaguesResponsiveBlock');
        });
     	couponPanel && Application.navigateTo(couponPanel.ID, false, [branchId, couponLeagues]);  
    }
    
    /* globals LeagueViewResponsiveBlock, BranchBlock, constHorseRacingBranchID, constGreyHoundRacingBranchID, Dom */
    api.OpenLeague = function (event, leagueID, branchID, element, eventTypeID, eventPathPartsCount, url)
    {
        if (branchID == constHorseRacingBranchID || branchID == constGreyHoundRacingBranchID)
        {
            OpenBranchPanel(event, branchID);
            return;
        }

        event && event.preventDefault && event.preventDefault();

        var leagueResponsivePanel = Array.find(api.Panels, function (panel) {
            return panel.Position == PanelPosition.CENTER &&
                panel.Blocks.some(function (block) { return typeof LeagueViewResponsiveBlock !== 'undefined' &&
                    (block instanceof LeagueViewResponsiveBlock || block.Name === "LeagueViewResponsiveBlock"); });
        });

        if (leagueResponsivePanel) {
            var path;
            if (element)
            {
                path = Dom.getFirstSubTreeHref(element);
                path = path ? path : '/' + leagueID;
            }

            if (url) {
                path = url;
            }
            
            api.navigateTo(leagueResponsivePanel.ID, false, [leagueID, branchID, eventTypeID, eventPathPartsCount], path, true);
            eventsManager.dispatchEvent('onLeagueChanged', leagueID, true);
            return;
        }

        // This is a patch for old search non-resposive block
        if (typeof SearchBlock !== 'undefined' && this === SearchBlock.Current)
        {
            this.openLeague(leagueID, element, branchID);
            return;
        }

        if (typeof BranchBlock !== 'undefined' && BranchBlock.Current)
        {
            BranchBlock.Current.openLeague(leagueID, branchID, element);
        }
    };

    api.OpenTournamentDetails = function(event, tournamentId, tournamentName, tournamentPathSlug, isMyTournament)
    {
        event && event.preventDefault && event.preventDefault();

        var tournamentDetailsPanel = Array.find(api.Panels, function(panel) {
            return panel.hasBlock("TournamentDetails");
        });

        var idOfHostingPanel = "tournament-details-panel";
        if(tournamentDetailsPanel != null) {
            idOfHostingPanel = tournamentDetailsPanel.ID;
        }

        var path = api.Navigation.getTournamentEventLink(tournamentId, tournamentPathSlug);
        api.navigateTo(idOfHostingPanel, false, [tournamentId, tournamentName, isMyTournament], path);
    }

    /* globals AllLeaguesPerCountryResponsiveBlock */
    api.OpenCountryPanel = function (event, branchId, countryName, regionId, element)
    {
        event && event.preventDefault && event.preventDefault();

        var countryResponsivePanel = Array.find(api.Panels, function (panel)
        {
            return panel.Position == PanelPosition.CENTER &&
                panel.hasBlock("AllLeaguesPerCountryResponsiveBlock");
        });

        if (countryResponsivePanel)
        {
            var path;
            if (element)
            {
                path = Dom.getFirstSubTreeHref(element);
            }

            api.navigateTo(countryResponsivePanel.ID, false, [ branchId, regionId], path, true);
        }
    };

    api.OpenToteEventView = function (leagueId, eventId)
    {
        var panel = findToteEventViewBlock();

        if (!panel) {
            return; 
        }

        msgBus && msgBus
            .emit(msgBusChannels.toteSEO.buildURL.request, { 
                leagueId: leagueId, 
                eventId: eventId,
                callback: function (path) {
                    api.navigateTo(panel.ID, false, [leagueId, eventId], api.Navigation.pageNavigationConfig.FullPagePath + path);
                } 
        })
    };

    function findToteEventViewBlock() {
        return api.findPanelByBlockClass("ToteEventViewBlock");
    }

    function initToteEventViewDeepLinking () {
        findToteEventViewBlock() && msgBus &&
            msgBus.emit(msgBusChannels.toteSEO.parseURL.request, {
                pageCode: api.Navigation.pageNavigationConfig.FullPagePath,
                url: decodeURIComponent(window.location.pathname.substring(window.location.pathname.indexOf(Application.Navigation.pageNavigationConfig.FullPagePath))),
                success: function(leagueId, eventId) {
                    openToteEventViewDeepLink(leagueId, eventId);
                },
                error: function() {
                    api.goHome();
                }
            });
    }

    function openToteEventViewDeepLink(leagueId, eventId) {
        var panel = findToteEventViewBlock();

        if (!panel) {
            return; 
        }

        api.navigateTo(panel.ID, false, [leagueId, eventId]);
    };

    api.findPanelByBlockClass = function (blockClass)
    {
        return Array.find(api.Panels, function(panel) {
            return Array.find(panel.Blocks, function (block) {
                return block.class === blockClass;
            });
        });
    };

    api.IsSeamlessOrWallet = function ()
    {
        return IsSeamlessOrWallet;
    };

    api.openResponsiveLogin = function (event) {
        let loginNavigationUrl = api.Navigation.pageNavigationConfig.LoginNavigationUrl;
        let result = true;
        if(loginNavigationUrl) {
            let loginPanel =  api.getPanel(loginNavigationUrl);
            if(loginPanel) {
                event && event.preventDefault();
                api.navigateTo(loginPanel.ID, false, [], undefined, true);
            } else {
                window.location.href = loginNavigationUrl;
            }
        } else {
            //Legacy login
            result = api.openPanelWithBlock(event, ['LoginResponsiveBlock', 'DanishLoginResponsiveBlock']);
        }

        return result;
    };

    api.openForgotPassword = function (event) {
        api.openPanelWithBlock(event, ['ForgotPasswordResponsiveBlock']);
    };

    api.openRegistration = function (event) {
        api.openPanelWithBlock(event, ['StepwiseRegistrationBlock']);
    };

    api.openPanelWithBlock = function(event, blockTypes) {
        var foundPanel = Array.find(api.Panels, (panel) => blockTypes.some(type => panel.hasBlock(type)));
        if (foundPanel) {
            event && event.preventDefault();
            api.navigateTo(foundPanel.ID, false, [], undefined, true);
        }

        return foundPanel;
    };

    /* globals ToggleMainNavigation */
    api.CloseLeftNav = function (callback)
    {
        var leftNavOpen = document.getElementsByClassName('fixedMenuEnabled')[0];
        if (leftNavOpen) {
            ToggleMainNavigation.toggle(callback);
        }
    };

    api.OpenInbox = function(event)
    {
        var inboxPanel = Array.find(api.Panels, function (panel)
        {
            return panel.hasBlock('InboxMessageListBlock');
        }); 
        event && event.preventDefault();
        inboxPanel && Application.navigateTo(inboxPanel.ID, false, []);
    };

    /* globals Dom, RacingBranchBlock, constHorseRacingBranchID, constInspiredVirtualSportsBranchID, constGreyHoundRacingBranchID, BetRadarSportsConstants */
    api.OpenBranchPanel = function (event, branchId, element, eventTypeId, eventPathPartsCount, additionalParams) {
        eventsManager.dispatchEvent('onBranchChanged', branchId, true);
        var path = Dom.getFirstSubTreeHref(element) || '';

        var branchPanel = ((branchId === constInspiredVirtualSportsBranchID) ? Application.Panels['virtuals-responsive-panel'] : undefined);
        branchPanel = ((BetRadarSportsConstants[branchId]) ? Application.Panels['virtual-betradar-panel'] : branchPanel);

        if (typeof branchPanel !== 'undefined')
        {
            Application.loadVirtualScriptBeforeNav(branchPanel.ID, branchPanel.isSidePanel(), [branchId, eventTypeId, eventPathPartsCount], path, branchId);
            event && event.preventDefault && event.preventDefault();
            return true;
        }

        if (branchId == constGreyHoundRacingBranchID || branchId == constHorseRacingBranchID)
        {
            var panel = Array.find(api.Panels, function (panel)
            {
                return panel.hasBlock('RacingCombinedView');
            });

            if (panel)
            {
                api.navigateTo(panel.ID, false, [undefined, branchId, eventTypeId, eventPathPartsCount], path);
                event && event.preventDefault && event.preventDefault();
                return true;
            }

            event && event.preventDefault && event.preventDefault();
            return true;
        }

        var leagueResponsivePanel = Array.find(api.Panels, function (panel)
        {
            return panel.hasBlock('TopLeaguesResponsiveBlock') || panel.hasBlock('LeagueListResponsiveBlock');
        });

        if (leagueResponsivePanel)
        {
            element && (path = Dom.getFirstSubTreeHref(element));
            api.navigateTo(leagueResponsivePanel.ID, false, [branchId, eventTypeId, eventPathPartsCount, additionalParams], path);
            event && event.preventDefault && event.preventDefault();
            return true;
        }

        var branchBlock = (typeof BranchBlock !== 'undefined') && BranchBlock.Current;
        if (branchBlock)
        {
            branchBlock.setNavigationPath(path);
            branchBlock.navigateTo(branchId);
            event && event.preventDefault && event.preventDefault();
            return true;
        }

        return false;
    };

    api.navigateToLimitationsSection = function (event) {
        navigateToAccountMenuLink(CONSTANTS.AccountMenuLinkTypes.Limitations);
    };

    api.navigateToPersonalDetails = function (event) {
        navigateToAccountMenuLink(CONSTANTS.AccountMenuLinkTypes.PersonalInfo);
    };

    api.navigateToDocumentUploadFacility = function (event)
    {
        navigateToAccountMenuLink(CONSTANTS.AccountMenuLinkTypes.AccountVerification);
    };

    api.navigateToAccountCheckDocumentBlock = function (event)
    {
        navigateToAccountMenuLink(CONSTANTS.AccountMenuLinkTypes.AccountCheckDocumentBlock);
    };

    api.navigateToOpenBets = function (event)
    {
        var panelToNavigate = Array.find(Application.Panels, function (panel) {
            return panel.Blocks.some(function (block)
            {
                return block.class == "OpenBets";
            });
        });
        panelToNavigate && Application.navigateTo(panelToNavigate.ID, false, [CONSTANTS.AccountMenuLinkTypes.OpenBets]);
    };

    function navigateToAccountMenuLink(AccountMenuLinkTypes) {
        var panelToNavigate;
        if (Application.deviceType.isDesktop()) {
            panelToNavigate = Array.find(Application.Panels, function (panel) {
                return panel.hasBlock('MyAccountResponsiveBlock');
            });
        }
        else {
            panelToNavigate = Array.find(Application.Panels, function (panel) {
                return panel.hasBlock(getMobileBlockByAccountMenuType(AccountMenuLinkTypes));
            });
        }

        panelToNavigate && Application.navigateTo(panelToNavigate.ID, false, [AccountMenuLinkTypes]);
    }

    function getMobileBlockByAccountMenuType(type) {
        switch (type) {
            case CONSTANTS.AccountMenuLinkTypes.PersonalInfo:
                return 'PersonalDetailsAndPasswordResponsiveBlock';
            case CONSTANTS.AccountMenuLinkTypes.AccountVerification:
                return 'DocumentUploadFacilityBlock';
            case CONSTANTS.AccountMenuLinkTypes.AccountCheckDocumentBlock:
                return 'AccountCheckDocumentBlock';
            case CONSTANTS.AccountMenuLinkTypes.Limitations:
                return 'AdvancedLimitationsResponsiveBlock';
            default:
                throw 'not implemented account type';
        }
    }

	api.OpenFullCashier = function(customUrl)
	{
      	if(typeof(QuickDepositSystemBlock) != "undefined")
        {
            QuickDepositSystemBlock.WidgetVersion = CONSTANTS.CashierWidgetVersions.FullCashier;
        }

        var isWithdrawal = customUrl ? customUrl.indexOf('tranType=1') > -1 : false;
        if (isWithdrawal && typeof ClientIncludeInManager !== 'undefined' && ClientIncludeInManager.CurrentTags.indexOf('useremailnotverified') > -1) {
            ClientIncludeInManager.refreshTags(function () {
                if (ClientIncludeInManager.CurrentTags.indexOf('useremailnotverified') > -1) {
                    eventsManager.dispatchEvent('showPleaseVerifyToAllowWithdrawMessage', true, true);
				}
				else {
                    api.OpenFullCashier(event, customUrl);
                }
            });
            return;
        }

		if (api.deviceType.isDesktop()) {
			api.HandlePopupRedirection(customUrl);
		} else {
            window.location.href = customUrl;
		}
    }

	api.HandlePopupRedirection = function(customUrl) {
		if (api.cashierPopupWindow && !api.cashierPopupWindow.closed) {
			api.cashierPopupWindow.focus();
			return;
		}

		var popupWidth = 686,
			popupHeight = 728;
		var popupTopPosition = screen.height / 2 - popupHeight / 2;
		var popupLeftPosition = screen.width / 2 - popupWidth / 2;

		if (!api.cashierPopupWindow || api.cashierPopupWindow.closed) {
			// IE 11 behaves strangely when you try to open the new window with the URL
			api.cashierPopupWindow = window.open('', '_blank', 'height=' + popupHeight
				+ ',width=' + popupWidth + ',menubar=yes,status=yes,titlebar=yes, top=' + popupTopPosition + ',left=' + popupLeftPosition + ',resizable=0, scrollbars=yes');
		}

		if (typeof(QuickDepositSystemBlock) !== "undefined" && QuickDepositSystemBlock.WidgetVersion === CONSTANTS.CashierWidgetVersions.QuickCashier && CheckForIE()) {
			var successfulCallback = function (result) {
				result = eval(result);
				if (result && result.RedirectionUrlIE) {
					QuickDepositSystemBlock.show(result.RedirectionUrlIE);
					PageMethods.ClearRedirectionUrlIE();
					clearInterval(interval);
				}
			};
			var callback = PageMethods.CheckRedirectionUrlIE.bind(PageMethods, successfulCallback);
			var interval = setInterval(callback, 2000);
		}

		// however it works just fine if you open empty window and assign the URL afterwards - WTF!?
		if (api.cashierPopupWindow) {
			api.cashierPopupWindow.location.href = customUrl;
		}

		UserInfo.onLogout['CashierPopup'] = function () {
			if (api.cashierPopupWindow) {
				api.cashierPopupWindow.close();
			}
			typeof (QuickDepositSystemBlock) != "undefined" && QuickDepositSystemBlock.hide();
		};
	}

    api.OpenQuickCashier = function (url) {
      	if(typeof(QuickDepositSystemBlock) != "undefined")
        {
            QuickDepositSystemBlock.WidgetVersion = CONSTANTS.CashierWidgetVersions.QuickCashier;
            QuickDepositSystemBlock.show(url);
        }
    }

    api.OpenCashierPopup = function (event, customUrl)
    {
        event && event.preventDefault();
        if(RegulationRuleId === RegulationRuleType.UnitedKingdom && !UserInfo.current.IsExternalyVerified)
        {
            IdentityVerifier.getVerificationStatus(function(status) {
                if(status != CONSTANTS.CustomerVerificationStatus.NotVerified) {
                    api.ContinueOpeningCashierPopup(event, customUrl);
                } else {
                    if(DisableExternalVerificationDuringRegistration && UrlForVerificationOptionsPage) {
                        window.location.href = UrlForVerificationOptionsPage;
                    } else {
                        api.navigateToAccountCheckDocumentBlock();
                    }
                }
            });
        } else {
            api.ContinueOpeningCashierPopup(event, customUrl);
        }
    }

    api.ContinueOpeningCashierPopup = function (event, customUrl)
    {
        var isWithdrawal = customUrl ? customUrl.indexOf('tranType=1') > -1 : false;
      	if(isWithdrawal)
        {
          api.OpenFullCashier(customUrl);
          return;
        }
        if (typeof ClientIncludeInManager !== 'undefined' && ClientIncludeInManager.CurrentTags.indexOf('quickregistrationinprocess') > -1) {
            navigateToPersonalInfo();
            return;
        }
        if (typeof(QuickDepositSystemBlock) === "undefined")
        {
            api.OpenFullCashier(customUrl ? customUrl : event.target.href);
        }

		if (api.cashierPopupWindow && !api.cashierPopupWindow.closed)
		{
            api.cashierPopupWindow.focus();
            return;
		}

		tryOpenQuickDeposit(customUrl);
    };

    function navigateToPersonalInfo() {
        var panelToNavigate;
        if (Application.deviceType.isDesktop()) {
            panelToNavigate = Array.find(Application.Panels, function (panel) {
                return panel.hasBlock('MyAccountResponsiveBlock');
            });
        } else {
            panelToNavigate = Array.find(Application.Panels, function (panel) {
                return panel.hasBlock('PersonalDetailsAndPasswordResponsiveBlock');
            });
        }
        if (panelToNavigate === CurrentPanel && Application.deviceType.isDesktop()) {
            eventsManager.dispatchEvent('MyAccountNavigationChange', CONSTANTS.AccountMenuLinkTypes.PersonalInfo, true);
        } else {
            panelToNavigate && Application.navigateTo(panelToNavigate.ID, false, [CONSTANTS.AccountMenuLinkTypes.PersonalInfo]);
        }
        eventsManager.dispatchEvent('showQuickRegistrationPersonalDetailsMessage', true, true);
    }

    function tryOpenQuickDeposit(customUrl) {

		var quickDepositErrorMessage = StorageUtils.getFromStorage(QuickDepositSystemBlock.SessionConstants.ErrorMessage);
		if (quickDepositErrorMessage)
		{
			QuickDepositSystemBlock.fillErrorMessage(quickDepositErrorMessage);
			QuickDepositSystemBlock.show();
			return;
		}

        var quickDepositWidgetVersion = StorageUtils.getFromStorage(QuickDepositSystemBlock.SessionConstants.WidgetVersion);
        if (typeof (quickDepositWidgetVersion) !== "undefined") {
            if (quickDepositWidgetVersion == CONSTANTS.CashierWidgetVersions.QuickCashier) {
                PageMethods.GetEpsPaymentSessionInfo(QuickDepositSystemBlock.PageID, QuickDepositSystemBlock.DestinationType, QuickDepositSystemBlock.Url, function (result) {
                    result = eval(result);
                    if (result.Message) {
                        QuickDepositSystemBlock.clearQuickDepositUrl();
                        QuickDepositSystemBlock.fillErrorMessage(result.Message);
                        QuickDepositSystemBlock.show();
                    }
                    if (result.RedirectRequest && result.RedirectRequest.WidgetVersion) {
                        if (result.RedirectRequest.WidgetVersion == CONSTANTS.CashierWidgetVersions.QuickCashier) {
                            Cookies.set(CONSTANTS.QuickCashier.cookieName, "true");
                            api.OpenQuickCashier(result.RedirectRequest.Url);
                        }
                        else if (result.RedirectRequest.WidgetVersion == CONSTANTS.CashierWidgetVersions.FullCashier) {
                            Cookies.set(CONSTANTS.QuickCashier.cookieName, "false");
                            api.OpenFullCashier(customUrl ? customUrl : QuickDepositSystemBlock.Url);
                        }
                    }
                }, function (error) {
                    if (error && error.Message) {
                        QuickDepositSystemBlock.fillErrorMessage(error.Message);
                        QuickDepositSystemBlock.show();
                    }
                });
            } else {
                Cookies.set(CONSTANTS.QuickCashier.cookieName, "false");
                api.OpenFullCashier(customUrl ? customUrl : QuickDepositSystemBlock.Url);
            }
        }
    }

    api.OpenLimitations = function() {
        var blockName = Application.deviceType.isDesktop() ? 'MyAccountResponsiveBlock' : 'LimitationsResponsiveBlock';
        var panelToNavigate = Array.find(Application.Panels, function (panel) {
            return panel.hasBlock(blockName);
        });
        
        if (!panelToNavigate) {
            var advancedBlockName = 'AdvancedLimitationsResponsiveBlock';
            panelToNavigate = Array.find(Application.Panels, function (panel) {
                return panel.hasBlock(advancedBlockName);
            });
        }

        if (panelToNavigate === CurrentPanel && Application.deviceType.isDesktop()) {
            eventsManager.dispatchEvent('MyAccountNavigationChange', CONSTANTS.AccountMenuLinkTypes.Limitations, true);
        }
        else {
            panelToNavigate && Application.navigateTo(panelToNavigate.ID, false, [CONSTANTS.AccountMenuLinkTypes.Limitations]);
        }
    };

    api.getDefaultPanelId = function () {
        return DefaultPanelID;
    };

    api.scrollToElement = function(el, offset, excludeHeader)
    {
        if (el && el instanceof HTMLElement)
        {
            var viewport = Application.deviceType.isTablet() ? this.centerPanelHolder.element : window,

                headerHeight = !excludeHeader && !Application.deviceType.isDesktop() && document.all('headerNav') ? document.all('headerNav').clientHeight : 0,

                mainView = el,

                _offset = (offset && typeof offset === 'number') ? offset : 0,

                hiddenOffset = headerHeight + _offset,

                currentScrollPos = Application.deviceType.isTablet() ? viewport.scrollTop : viewport.pageYOffset,

                mainViewY = mainView.getBoundingClientRect().top + currentScrollPos - hiddenOffset;


            if (mainViewY === currentScrollPos)
            {
                return false;
            }

            else
            {
                viewport.scrollTo(0, mainViewY);
            }
        }
    };

    api.OpenMobileLiveBetting = function (event, panelId, branchID)
    {
        
        if(api.deviceType.isTablet())
        {
            api.OpenGame(event,0,true,0,false,branchID)
            return
        }
        var mobileLiveBettingPanel = Array.find(api.Panels, function (panel) {
            return  panel.Position == PanelPosition.CENTER && panel.hasBlock('LiveNowBettingResponsiveBlock');
        });
        
        if (mobileLiveBettingPanel) {
            event && event.preventDefault && event.preventDefault();
            api.navigateTo(mobileLiveBettingPanel.ID, false, [0, 0, branchID]);
        }
    };

    api.initAsiaDeepLinks = function () {
        //asian view deep link initialization
        if (isAsianView) {
            var asianStore = window["RootStore"];
            if (asianStore) {
                __preloadBID !== "false"       && !!__preloadBID       && (asianStore.deepLink.BranchID = __preloadBID);
                __preloadLeagueId !== "false"  && !!__preloadLeagueId  && (asianStore.deepLink.LeagueID = __preloadLeagueId);
                __preloadMID !== "false"       && !!__preloadMID       && (asianStore.deepLink.MasterEventID = __preloadMID);
                __preloadEventDate !== "false" && !!__preloadEventDate && (asianStore.deepLink.EventDate = new Date(__preloadEventDate));
                __preloadLineID !== "false"    && !!__preloadLineID    && (asianStore.deepLink.LineID = __preloadLineID);
            }
        }
	}
	
	api.initNativeHomeRedirection = function() {
		if (typeof (api.BeforeNavigation["NativePanelOnBackRedirect"]) !== "function") {
			api.BeforeNavigation["NativePanelOnBackRedirect"] = NativePanelRedirection.onBackRedirect;
		}
	}
    return api;

})());

window.Application = Application;

window.onload = function ()
{
    'use strict';
    setTimeout(function () {
        Application.init();
        Application.removeSplash();
    }, 0);
    setTimeout(function () { window.scrollTo(0, 1); }, 100);
};

if (InitialEventListeners && InitialEventListeners.init)
{
    document.addEventListener('DOMContentLoaded', InitialEventListeners.init, false);
}

/* exported stopClick */
function stopClick(elem)
{
    'use strict';

    elem.setAttribute('touched', 1);
}

window.stopClick = stopClick;

/* exported canClick */
function canClick(elem)
{
    'use strict';

    if (elem.getAttribute('touched'))
    {
        elem.removeAttribute('touched');
        return false;
    }

    return true;
}

window.canClick = canClick;

window.addEventListener('load', function ()
{
    'use strict';

    var langCookieVal = Cookies.get('lng');
    if (langCookieVal && (LangID != langCookieVal))
    {
        window.location.reload();
    }
});

/* jshint ignore:start */
var AdjustIFrameForIPhone = (function () {
    'use strict';

    var iOS = (/iPad|iPhone|iPod/i).test(navigator.userAgent);
    var isIframe = window.self !== window.top;
    var hasScreenProperties = typeof screen.width === 'number' && typeof screen.height === 'number';

    var html = document.documentElement;

    function setWidth() {
        html.style.width = readDeviceOrientation();
    }

    function readDeviceOrientation() {
        switch (window.orientation) {
            // Portrait and Portrait (Upside-down)
            case 0:
            case 180:
                return screen.width + 'px';
                break;
                // Landscape (Clockwise) and Landscape (Counterclockwise)
            case -90:
            case 90:
                return screen.height + 'px';
                break;
        }
    }

    function activate() {
        if (isIframe === true && iOS === true && hasScreenProperties === true) {
            setWidth();
            window.addEventListener('orientationchange', setWidth, false);
        }
    }

    function deactivate() {
        html.style.width = 'auto';
        window.removeEventListener('orientationchange', setWidth);
    }

    return {
        activate: activate,
        deactivate: deactivate
    };
})();

// NOTE: Screen's width and height don't work for iOS devices with notch so 
// we switched to a CSS only solution: https://stackoverflow.com/questions/23083462/how-to-get-an-iframe-to-be-responsive-in-ios-safari/23083463#23083463
// If everything works flawlessly we should remove the next line
// AdjustIFrameForIPhone.activate();

window.AdjustIFrameForIPhone = AdjustIFrameForIPhone;

// Attach remove handler for "isWindowLoading" classname
window.addEventListener('load', function () {
    if (document && document.documentElement && document.documentElement.classList) {
        document.documentElement.classList.remove('isWindowLoading');
    }
});

includeExtension('/JSComponents/Mobile/AppExt.js');

function OpenRacingGame(event, id, regionID, mode, branchID, trapMarketID, bettingWithoutFavID, bettingWithoutTwoFavID, placeOnlyID, matchBettingID, toteId, totePlaceOnlyId, auPlaceOnlyId)
{
    'use strict';

    if (event.preventDefault)
    {
        event.preventDefault();
    }

    if (UseUrlNav && event.currentTarget && event.currentTarget.hasAttribute('href'))
    {
        var path = event.currentTarget.attributes['href'].value;
        if (!/^javascript/.test(path))
        {
            RacingGameBlock.Current.setNavigationPath(path);
        }
    }

    RacingGameBlock.Current.navigateTo(id, regionID, mode, branchID, trapMarketID, bettingWithoutFavID, bettingWithoutTwoFavID, placeOnlyID, matchBettingID, toteId, totePlaceOnlyId, auPlaceOnlyId);
}

function CheckForIE() {
	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");

	if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
		return true;
	}

	return false;
}

window.OpenRacingGame = OpenRacingGame;
window.CheckForIE = CheckForIE;

